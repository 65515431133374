import { useState } from 'react';
import './App.css';
import DigitButton from './components/DigitButton';
import OperationButton from './components/OperationButton';
import History from './components/History';

function App() {

  const [mainNum, setMainNum] = useState("0")
  const [secondNum, setSecondNum] = useState("")
  const [operationSign, setOperationSign] = useState("")
  const [history, setHistory] = useState([])

  function insertNum(number){
    if(mainNum === "0" || mainNum === "-0"){
      setMainNum(number.toString())
      return
    }

    number = mainNum + number
    setMainNum(number)
  }

  function addDecimal(dot) {
    if(mainNum.includes(".")){return}

    setMainNum(mainNum + dot)
  }

  function calculate(sign) {
    switch(sign) {
      case "+":
        return parseFloat(secondNum) + parseFloat(mainNum)
      case "-":
        return parseFloat(secondNum) - parseFloat(mainNum)
      case "×":
        return parseFloat(secondNum) * parseFloat(mainNum)
      case "÷":
        return parseFloat(secondNum) / parseFloat(mainNum)
      default:
        break;
    }
  }

  function operate(sign){
    switch(sign){
      case "C":
        setMainNum("0")
        setSecondNum("")
        setOperationSign("")
        break;
      case "←":
        if(mainNum === "0"){break}

        if(mainNum.length <= 1){
          setMainNum("0")
          break
        }

        setMainNum(mainNum.slice(0, -1).toString())
        break;
      case "+":
      case "-":
      case "×":
      case "÷":
        if(operationSign === ""){
          setSecondNum(mainNum)
          setOperationSign(sign)
          setMainNum("0")
        } else {
          //add feature here. (it would show like this on the upper row when you press "=": 34+12+1/4+7)
          setSecondNum(calculate(operationSign).toString())
          setOperationSign(sign)
          setMainNum("0")
        }
        break;
      case "=":
        if(secondNum === "" || operationSign === ""){break}

        setHistory([...history, {
          id: history.length + 1,
          firstNum: secondNum,
          secondNum: mainNum,
          operation: operationSign,
          equals: calculate(operationSign).toString()
        }])

        setMainNum(calculate(operationSign).toString())
        setSecondNum("")
        setOperationSign("")
        break;
      case "±":
        setMainNum((parseFloat(mainNum) * -1).toString())
        break;
      default:
        break;
    }
  }

  return (
    <div className="app">
      <div className='calc-container'>
        <div className="calc-display">
          <p className='calc-display-secondary'>{secondNum} {operationSign}</p>
          <p className='calc-display-main'>{mainNum}</p>
        </div>
        <OperationButton value="±" operate={operate}/>
        <OperationButton value="C" operate={operate}/>
        <OperationButton value="←" operate={operate}/>
        <OperationButton value="÷" operate={operate}/>
        <DigitButton value="1" add={insertNum}/>
        <DigitButton value="2" add={insertNum}/>
        <DigitButton value="3" add={insertNum}/>
        <OperationButton value="×" operate={operate}/>
        <DigitButton value="4" add={insertNum}/>
        <DigitButton value="5" add={insertNum}/>
        <DigitButton value="6" add={insertNum}/>
        <OperationButton value="+" operate={operate}/>
        <DigitButton value="7" add={insertNum}/>
        <DigitButton value="8" add={insertNum}/>
        <DigitButton value="9" add={insertNum}/>
        <OperationButton value="-" operate={operate}/>
        <DigitButton value="." add={addDecimal}/>
        <DigitButton value="0" add={insertNum}/>
        <OperationButton value="=" operate={operate}/>
      </div>
      <History history={history}/>
    </div>
  );
}

export default App;
